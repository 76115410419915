import Image from 'next/image';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { trpc } from '@/lib/trpc';
import Dialog from '@/components/Dialog';
import Digits from '@/components/auth/Digits';
import Button from '@/components/Button';
import HelpText from '@/components/HelpText';
import CopyIcon from '@/icons/copy.svg';

interface SignUpModalProps {
  qrCode?: string;
  open: boolean;
  invalidateUserOnSuccess?: boolean;
  onOpenChange: (open: boolean) => void;
  redirectToDashboard?: boolean;
  cancelText?: string;
}

const schema = z.object({
  code: z.string().min(6, 'A 2FA code is required'),
});

type SignUpData = z.infer<typeof schema>;

export default function SignUpModal({
  qrCode,
  open,
  onOpenChange,
  redirectToDashboard = true,
  invalidateUserOnSuccess = false,
  cancelText = 'Cancel',
}: SignUpModalProps) {
  const router = useRouter();

  const ctx = trpc.useContext();

  const { mutate, isLoading, isError, isSuccess, data, reset } =
    trpc.auth.enableTwoFactorAuth.useMutation({
      onSuccess: () => {
        if (invalidateUserOnSuccess) {
          ctx.users.getCurrentUser.invalidate();
        }
      },
    });

  const { register, formState, handleSubmit, ...methods } = useForm<SignUpData>(
    {
      resolver: zodResolver(schema),
    }
  );

  const closeDialog = () => {
    onOpenChange(false);
    reset();

    if (redirectToDashboard) router.push('/');
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        onOpenChange(newOpen);
        if (newOpen === false) {
          closeDialog();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="sign-up">
          {isSuccess ? (
            <>
              <h2 className="sign-up__heading">Password recovery key</h2>
              <p className="sign-up__subheading">
                This backup code can be used in the event that you cannot
                receive your two-factor authentication code.
              </p>
              {data?.key && (
                <div className="sign-up__wrap">
                  <p className="sign-up__key">{data.key}</p>

                  <button
                    aria-label="Copy key"
                    className="sign-up__copy"
                    type="button"
                    title="Copy key"
                    onClick={() => navigator?.clipboard?.writeText(data.key)}
                  >
                    <CopyIcon className="sign-up__copy-icon" />
                  </button>
                </div>
              )}
              <div className="sign-up__footer">
                <Button
                  className="sign-up__button sign-up__button--continue"
                  onClick={() => closeDialog()}
                  size="small"
                  type="button"
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <FormProvider
              register={register}
              formState={formState}
              handleSubmit={handleSubmit}
              {...methods}
            >
              <form
                onSubmit={handleSubmit((values) => mutate(values))}
                className="sign-up__form"
              >
                <h2 className="sign-up__heading">
                  Set up two-factor authentication
                </h2>

                <p className="sign-up__subheading">
                  To enable two-factor authentication, please scan this QR code
                  with your Authenticator App and enter the verification code
                  below.
                </p>

                {isError && (
                  <HelpText className="sign-up__error" type="warning">
                    Error enabling Two-factor authentication. Please enter a new
                    verification code.
                  </HelpText>
                )}

                {qrCode && (
                  <figure className="sign-up__figure">
                    <Image
                      className="sign-up__qr"
                      src={qrCode}
                      width={128}
                      height={128}
                      alt="QR Code"
                    />
                  </figure>
                )}

                <Digits className="sign-up__digits" />

                <div className="sign-up__footer">
                  <Button
                    className="sign-up__button sign-up__button--close"
                    onClick={() => closeDialog()}
                    size="small"
                    type="button"
                    variant="secondary-outline"
                  >
                    {cancelText}
                  </Button>

                  <Button
                    className="sign-up__button sign-up__button--enable"
                    disabled={isLoading}
                    isLoading={isLoading}
                    size="small"
                    type="submit"
                  >
                    Enable
                  </Button>
                </div>
              </form>
            </FormProvider>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  );
}
